<template>
  <section>
    <div class="hide">
      <div class="row">
        <div class="col-12 mt-2">
          <div id="prodboard-container"></div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 col-lg-6">
          <!-- <h2 style="font-size: 3em; margin-bottom: 0px;">
            <span style="font-weight: bold;font-family: kittithada;"
              >Wardrobe</span
            >Planner
          </h2>
          <p style="font-family: kittithada;margin-top: -20px;">
            by Thaiwatsadu.com
          </p> -->
        </div>
        <div class="col-sm-12 col-lg-6">
          <div class="row">
            <div class="col-9 col-lg-9 d-flex align-items-end flex-column">
              <p style="padding: 0px;margin: 0px;">ยอดสินค้ารวม</p>
              <p
                id="total_price"
                style="padding: 0px;margin: 0px;font-weight: bold;font-size: 2em;margin-top: -25px;"
              >
                0.00.-
              </p>
            </div>
            <div class="col-3 col-lg-3 d-flex align-items-end flex-column">
              <button
                @click="addCart"
                class="btn"
                style="background-color: #55246c;color:white;margin-top:5px;"
              >
                ใส่รถเข็น
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="loading">Loading&#8230;</div> -->
    <div class="loading"></div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modal_ref"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body" style="background-color: #DDDDDD;">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="opacity: 1;"
            >
              <span aria-hidden="true" style="position:absolute;">x</span>
            </button>
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 text-center mt-2">
                  <p style="font-family: KittithadaBold75;font-size: 1.2em;">
                    ระบุชื่อตู้เสื้อผ้ารายการนี้
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-10 mb-2">
                  <input
                    id="val_reference"
                    type="text"
                    maxlength="30"
                    class="form-control"
                    aria-describedby="ห้องนอน 1, ห้องนอน 2"
                    placeholder="ห้องนอน 1, ห้องนอน 2"
                  />
                </div>

                <div class="col-2" style="padding:0px;">
                  <button
                    @click="saveReference"
                    type="button"
                    class="btn btn-primary"
                    style="background-color: #55246c;border:none;"
                  >
                    <span>ตกลง</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import * as serviceAPI from "@/services/API.service";
  import * as serviceMain from "@/services/main.service";
  import * as constant from "@/services/constant";
  const instance = constant.instance;
  const original = constant.original;
  const company = constant.company;

  export default {
    name: "ProdboardComp",
    data: function() {
      return {
        VIPID: "",
        items: [],
        isSaved: false,
        prodIdSave: this.prodId,
        imagePrview64: "",
        title: "",
        appended: true,
      };
    },
    components: {},
    async mounted() {
      // console.log(this.prodId);
      $(".hide").hide();
      $(".loading").show();
      this.getVIPID();
      if (this.VIPID !== "") {
        // console.log("showProdboard");
        this.showProdboard();
        this.loginProdboard();
      } else {
        this.$router.push({ name: "FindUser" });
      }
    },
    props: ["prodId"],
    methods: {
      plus(e) {
        e.old = e.old + 1;
      },
      async showProdboard() {
        try {
          // console.log("showProdboard");
          var options = {
            company: company,
            instance: instance,
            original: original,
            host: "constructor.prodboard.com",
          };
          var focus = false;
          var container = $("#prodboard-container");
          var content = $(".content", container);

          var frame = $("<iframe allowFullscreen='true' />");
          var protocol = "https://"; //(document.location.protocol == "https:" ? "https://" : "http://");
          var src =
            protocol +
            options.host +
            "/" +
            options.company +
            "/" +
            options.instance +
            "/";
          var mode =
            (options.original || "").indexOf("#") < 0 ? "hash" : "search";

          function url_query(query) {
            query = query.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var expr = "[\\?&]" + query + "=([^&#]*)";
            var regex = new RegExp(expr);
            var results = regex.exec(window.location.href);
            if (results !== null) {
              return results[1];
            } else {
              return false;
            }
          }
          var setState = function(state) {
            if (mode === "hash") window.location.hash = state;
            if (mode === "search") {
              var query = window.location.search || "";
              var hash = window.location.hash;
              var path = window.location.pathname;

              if (query.length && query[0] === "?") query = query.substr(1);
              var parts = query.split("&") || [];
              var exist = -1;
              for (var i = 0; i < parts.length; i++) {
                var p = parts[i];
                if (p.startsWith("prodboard=")) {
                  exist = i;
                }
              }
              if (exist !== -1)
                parts[exist] = "prodboard=" + window.encodeURIComponent(state);
              else parts.push("prodboard=" + window.encodeURIComponent(state));
              query = "?" + parts.join("&");

              var url = path + query + hash;
              window.history.pushState({}, window.title, url);
            }
          };

          var getState = function() {
            if (mode === "hash") return window.location.hash;
            if (mode === "search") return url_query("prodboard");
          };

          var initialState = getState();
          if (initialState) src += "#" + initialState;

          frame.attr("src", src);
          frame.css({ width: "100%", height: "100%", border: "none" });
          container.html(frame);

         // frame.html(container.html());

          var focus = function(value) {
            if (!options.focus) return;

            if (value) {
              container.addClass("focus");
              frame.show();
              content.hide();
            } else {
              container.removeClass("focus");
              frame.hide();
              content.show();
            }
          };

          var handlers = {};

          var processMessage = function(event) {
            var host = protocol + options.host;
            if (event.origin !== host) return;

            if (event.data.command === "redirect")
              window.location.href = event.data.state;
            else if (event.data.command === "set_state")
              console.log("setState");
            //setState(event.data.state);
            else if (event.data.command === "focus") console.log("focus");
            //focus(event.data.state);
            else {
              for (var action in handlers) {
                if (
                  event.data.action === action &&
                  handlers.hasOwnProperty(action)
                ) {
                  var handler = handlers[action];
                  if (handler) handler(event.data.payload);
                }
              }
            }
          };
   

          window.addEventListener("message", processMessage, false);

          if (options.focus) {
            container.on("click", function(e) {
              focus(!container.hasClass("focus"));
            });
            frame.css({ display: "none" });
          }

          window.prodboard = {
            exec: function(action, payload) {
              var win = frame.get(0).contentWindow;
              win.postMessage(
                { action: action, payload: payload },
                protocol + options.host
              );
            },
            on: function(action, handler) {
              handlers[action] = handler;
            },

            signIn: function(token) {
              // console.log("signIn 11111");
              // console.log(token);
              var win = frame.get(0).contentWindow;

              win.postMessage(
                { command: "signIn", token: token },
                protocol + options.host
              );
            },
            load: function(data) {
              // possible options:
              // new                      - create new project
              // project-{id}             - load project (last version)
              // project-{id}-{version}   - load project (specific version)
              // sample-{id}              - load template
              var win = frame.get(0).contentWindow;
              console.log(data);
              win.postMessage(
                { command: "load", data: data },
                protocol + options.host
              );
            },
          };
          let getStoreId = this.getStore;
          async function convert_currency(number) {
            var formatter = new Intl.NumberFormat("th-TH", {
              style: "currency",
              currency: "THB",
            });
            return formatter.format(number);
          }
          async function getTotalPrices(items) {
            try {
              const data = {
                items: items,
                store_id: getStoreId,
              };
              console.log(items);
              let getAPI = await serviceAPI.call_API(
                "post",
                `prodboard/getTotalPrices`,
                data,
                "auth"
              );

              let res = getAPI.data;
              console.log(res);
              const final_total = await convert_currency(res.total_price);
              $("#total_price").text(final_total + ".-");
              $(".loading").hide();
              $(".hide").show();
            } catch (error) {
              serviceMain.showErrorAlert(this, error.message);
            }
          }
          var self = this;
          async function setIsSaved() {
            self.isSaved = true;
          }
          async function setProjectId(id) {
            // console.log(id);
            self.prodIdSave = id;
          }
          prodboard.on("login", async function(payload) {
            let id = window.location.pathname.split("/");
            id = id[2];
            console.log(payload);
            if (payload === "" || null) {
              console.log("reload");
              location.reload();
            } else {
              if (id != 0) {
                console.log(id);
                prodboard.load(`project-${id}`);
                const data = {
                  id: id,
                  store_id: getStoreId,
                };
                let getAPI = await serviceAPI.call_API(
                  "post",
                  `prodboard/getProjectByID`,
                  data,
                  "auth"
                );
                let res = getAPI.data.data;
                console.log(res);
                console.log(id);
                const final_total = await convert_currency(res.total_price);
                console.log(final_total);
                $("#total_price").text(final_total + ".-");
                $(".loading").hide();
                $(".hide").show();
              } else {
                $(".loading").hide();
                $(".hide").show();
              }
            }
            //console.log(payload);
          });
          prodboard.on("calculated", async function(payload) {
            if (payload.items.length > 0) {
              // console.log(payload);
              await getTotalPrices(payload.items);
            }
          });
          prodboard.on("save", async function(payload) {
            await setIsSaved();
            console.log(payload.id);
            await setProjectId(payload.id);
          });
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async loginProdboard() {
        try {
          let data = {
            VIPID: this.getQTCUSTINFO.VIPID,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `prodboard/tokenProdboard`,
            data,
            "auth"
          );
          let getToken = getAPI.data.data;
          if (getToken == null) {
            this.registerProdboard();
          } else {
            var token_string = getToken.replaceAll('"', "");
            //  prodboard.signIn(token_string);
            setTimeout(function() {
              prodboard.signIn(token_string);
            }, 4000);
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async registerProdboard() {
        try {
          let data = {
            VIPID: this.getQTCUSTINFO.VIPID,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `prodboard/registerProdboard`,
            data,
            "auth"
          );
          let res = getAPI.data.data;
          // console.log(data);
          if (res.success) {
            this.loginProdboard();
          } else {
            serviceMain.showErrorAlert(this, error.message);
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async addCart() {
        try {
          $(".loading").show();
          const isSaved = this.isSaved;
          if (isSaved == false) {
            $(".loading").hide();
            this.$swal.fire({
              confirmButtonText: "ตกลง",
              html:
                '<h3 style="font-weight:bolder;color:black;">กรุณา save project ก่อน</h3>',
              confirmButtonColor: "#55246c",
            });
          } else {
            let prodIdSave = this.prodIdSave;
            let res = await this.getProjectByID(prodIdSave);
            this.items = res;
            if (res.total_price == 0) {
              $(".loading").hide();
              this.$swal.fire({
                confirmButtonText: "ตกลง",
                html:
                  '<h3 style="font-weight:bolder;color:black;">ท่านยังไม่มี สินค้าในตระกร้า</h3>',
                confirmButtonColor: "#55246c",
              });
            } else {
              $(".loading").hide();
              $("#modal_ref").modal({
                show: true,
                backdrop: "static",
                keyboard: false,
              });
              let name_project = res.reference;
              if (
                name_project == "" ||
                name_project == "null" ||
                typeof name_project == "undefined"
              ) {
                name_project = "";
              }
              $("#val_reference").val(name_project);
            }
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async getVIPID() {
        try {
          this.VIPID = this.getQTCUSTINFO.VIPID;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async getProjectByID(id) {
        try {
          let getStoreId = this.getStore;
          const data = {
            id: id,
            store_id: getStoreId,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `prodboard/getProjectByID`,
            data,
            "auth"
          );
          return getAPI.data.data;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async saveReference() {
        try {
          var val_reference = $("#val_reference").val();
          this.title = val_reference;
          if (val_reference != "") {
            $("#modal_ref").modal("hide");
            $(".loading").show();
            // Query filter - project has specified status, (0 - Planning, 1 - Ordered, 2 - Confirmed, 3 - Completed)
            const data = { id: this.prodIdSave, ref: val_reference, status: 1 };
            let getAPI = await serviceAPI.call_API(
              "post",
              `prodboard/saveReference`,
              data,
              "auth"
            );
            let res = getAPI.data.data;
            if (res.success) {
              this.sentToCart();
            } else {
              serviceMain.showErrorAlert(this, error.message);
              $(".loading").hide();
            }
          } else {
            $("#val_reference").focus();
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async sentToCart() {
        try {
          let items = this.items;
          let getStoreId = this.getStore;
          let prices = this.items.prices;
          let ref = this.getRef;
          let prices_format = [];
          var _this = this;
          let cntItems = 0;
          if (prices.length > 0) {
            for (var key in prices) {
              if (prices.hasOwnProperty(key)) {
                var innerObj = {};
                innerObj["sku"] = prices[key].code;
                innerObj["qty"] = prices[key].quantity;
                cntItems += prices[key].quantity;
                prices_format.push(innerObj);
              }
            }
            this.qtShopItem = cntItems;
          }
          const data = {
            REF: ref,
            project_id: items.id,
            store: getStoreId,
            image_preview: items.preview,
            title: this.title,
            sub_title: "ตู้เสื้อผ้า DIY", //fix
            items: prices_format,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `prodboard/sentToCart`,
            data,
            "auth"
          );
          if (getAPI.status_code == 200) {
            //
            $(".loading").hide();
            _this.$router.push({
              name: "Cart",
            });
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async getBase64Image(url, callback, outputFormat) {
        try {
          var img = new Image();
          var canvas = document.createElement("CANVAS");
          var ctx = canvas.getContext("2d");
          var dataURL;
          img.crossOrigin = "Anonymous";
          img.onload = function() {
            canvas.height = canvas.width * (img.height / img.width);
            // step 1 - resize to 50%
            var oc = document.createElement("canvas"),
              octx = oc.getContext("2d");
            oc.width = img.width * 0.5;
            oc.height = img.height * 0.5;
            octx.drawImage(img, 0, 0, oc.width, oc.height);
            // step 2
            octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);
            // step 3, resize to final size
            ctx.drawImage(
              oc,
              0,
              0,
              oc.width * 0.5,
              oc.height * 0.5,
              0,
              0,
              canvas.width,
              canvas.height
            );
            dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
            canvas = null;
          };
          img.src = url;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
    },
    computed: {
      qtShopItem: {
          get(){
              return this.$store.getters['customer/getQTSHOPITEM']
          }, 
          set(value) {
              this.$store.dispatch('customer/setQTSHOPITEM', value)
          }
      },
      getRef: function() {
        return this.$store.getters["customer/getQTCUSTREF"];
      },
      getStore: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      getStoreName: function() {
        return this.$store.getters["service/getQTSTORENAME"];
      },
      getBU: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      getBuName: function() {
        let getBU = this.$store.getters["customer/getQTBU"];
        if (getBU == "bnb") {
          return "บ้านแอนด์บียอนด์";
        } else if (getBU == "at1") {
          return "Auto1";
        } else {
          return "ไทวัสดุ";
        }
      },
      getQTCUSTINFO: function() {
        return this.$store.getters["customer/getQTCUSTINFO"];
      },
      getQTUSERID: function() {
        return this.$store.getters["auth/getQTUSERID"];
      },
      getIs10101: {
        get() {
          return this.$store.getters["service/getIs10101"];
        },
        set(value) {
          this.$store.dispatch("service/setIs10101", value);
        },
      },
    },
    watch: {
      getStore: function() {
        this.showList();
      },
      getReloadOrderInfo: function() {
        this.showList();
      },
    },
  };
</script>

<style scoped>
  #prodboard-container {
    height: 65vh;
  }
  #prodboard-container iframe::nth-child(2) {
    display: none;
  }
  /* Absolute Center Spinner */
  .loading {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: #000000;
    background-image: url("/img/loadsmall.gif");
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000000;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
</style>
